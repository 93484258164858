<template>
  <div>
    <ui-component-drawer
      v-if="showSideDrawer"
      :drawerTitle="drawerTitle"
      :showDrawer="showSideDrawer"
      :onClickClose="hideDrawer"
      position="right"
    >
      <template v-slot:content>
        <voucher-summary v-if="showVoucherSummary" :voucherId="voucherId" />
      </template>
    </ui-component-drawer>

    <div v-if="voucherApplied">
      {{ voucherName }}
      <a @click="setShowVoucherSummary()">
        <span class="icon">
          <font-awesome-icon :icon="['fas', 'question-circle']" />
        </span>
      </a>
      <a
        @click="revertVoucher"
        v-if="!isLocked"
        class="button is-small"
        :class="{ 'is-loading': isChecking }"
        >{{ $t('Components.Vouchers.CheckVoucher.Button_Delete') }}</a
      >
    </div>

    <div v-if="!voucherApplied">
      <div class="field has-addons">
        <div class="control">
          <input
            type="text"
            v-model="voucherCode"
            v-on:keyup.13="checkVoucher"
            class="input is-small"
          />
        </div>
        <div class="control">
          <a
            @click="checkVoucher"
            class="button is-small is-primary"
            :class="{ 'is-loading': isChecking }"
            >{{
              $t('Components.Vouchers.CheckVoucher.Button_Check-voucher')
            }}</a
          >
        </div>
      </div>

      <article class="message is-warning has-margin-top">
        <div class="message-body" v-if="voucherCheckMessage.length > 0">
          <span>{{ voucherCheckMessage }}</span>
          <span
            ><button @click="voucherCheckMessage = ''" class="delete"></button
          ></span>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import voucherProvider from '@/providers/voucher'

const VoucherSummary = () => import('@/components/Vouchers/VoucherSummary')

export default {
  components: {
    'voucher-summary': VoucherSummary
  },

  props: {
    cart: {
      default: null,
      type: Object
    },

    isLocked: {
      default: false,
      type: Boolean
    },

    reservation: {
      default: null,
      type: Object
    }
  },

  data() {
    return {
      isChecking: false,
      mCart: this.cart,
      mReservation: this.reservation,
      showSideDrawer: false,
      showVoucherSummary: false,
      voucher: null,
      voucherId: 0,
      voucherName: '',
      voucherCode: '',
      voucherCheckMessage: ''
    }
  },

  computed: {
    voucherApplied() {
      let self = this
      let applied = false

      if (
        (self.mCart && self.mCart.VoucherId) > 0 ||
        (self.mReservation && self.mReservation.VoucherId > 0)
      ) {
        if (self.mCart) {
          self.voucherId = self.mCart.VoucherId
          self.voucherName = self.mCart.VoucherName
        }

        if (self.mReservation) {
          self.voucherId = self.mReservation.VoucherId
          self.voucherName = self.mReservation.VoucherName
        }

        applied = true
      }

      return applied
    }
  },

  methods: {
    checkVoucher() {
      let self = this
      self.voucherCheckMessage = ''

      if (self.voucherCode.length > 0) {
        if (self.cart && self.cart.Id > 0) {
          self.applyCartVoucher()
        }

        if (self.reservation && self.reservation.Id > 0) {
          self.applyReservationVoucher()
        }
      } else {
        self.voucherCheckMessage = 'No voucher code entered'
      }
    },

    applyCartVoucher() {
      let self = this
      self.isChecking = true

      voucherProvider.methods
        .applyCartVoucher(self.voucherCode, self.mCart.CartKey)
        .then(response => {
          if (response.status === 200) {
            let result = response.data

            if (result.StatusCode < 1) {
              self.voucherCheckMessage = result.Message
            }

            if (result.StatusCode === 1) {
              self.mCart = result.Cart
              self.$emit('cartVoucherApplied', result.Cart)
            }
          }
        })
        .finally(() => {
          self.isChecking = false
        })
    },

    applyReservationVoucher() {
      let self = this
      self.isChecking = true

      voucherProvider.methods
        .applyReservationVoucher(self.voucherCode, self.mReservation.Id)
        .then(response => {
          let result = response.data

          if (result.StatusCode < 1) {
            self.voucherCheckMessage = result.Message
          }

          if (result.StatusCode === 1) {
            self.mReservation = result.Reservation
            self.$emit('reservationVoucherApplied', result.Reservation)
          }
        })
        .finally(() => {
          self.isChecking = false
        })
    },

    revertVoucher() {
      if (this.cart && this.cart.VoucherId > 0) {
        this.revertCartVoucher()
      }

      if (this.reservation && this.reservation.VoucherId > 0) {
        this.revertReservationVoucher()
      }
    },

    revertCartVoucher() {
      let self = this
      self.isChecking = true

      voucherProvider.methods
        .revertCartVoucher(self.mCart.CartKey, self.voucherId)
        .then(response => {
          if (response.status === 200) {
            self.mCart = response.data
            self.$emit('cartVoucherApplied', response.data)
          }
        })
        .finally(() => {
          self.isChecking = false
        })
    },

    revertReservationVoucher() {
      let self = this
      self.isChecking = true

      voucherProvider.methods
        .revertReservationVoucher(self.mReservation.Id, self.voucherId)
        .then(response => {
          if (response.status === 200) {
            self.mReservation = response.data
            self.$emit('reservationVoucherApplied', response.data)
          }
        })
        .finally(() => {
          self.isChecking = false
        })
    },

    setShowVoucherSummary() {
      this.drawerTitle = 'Voucher detail'
      this.showSideDrawer = true
      this.showVoucherSummary = true
    },

    hideDrawer() {
      this.drawerTitle = ''
      this.showSideDrawer = false
      this.showVoucherSummary = false
    }
  }
}
</script>
